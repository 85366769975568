import React, { useContext, useState } from 'react';
import { UserContext } from '../../userContext';
import { Avatar, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Storefront } from '@material-ui/icons';
import { useRut } from '../../../../../utils/formatRut';
import { formatDate } from '../../../../../utils/format';
import Button from '../../../../../components/common/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UsersAPI from '../../../user-api';

export const DatosEmprendimiento = ({ setShowAlert, setMessageAlert }) => {
  const [state, dispatch] = useContext(UserContext);
  const history = useHistory();
  const { format } = useRut('');
  const { user } = state;
  const [avatar, setAvatar] = useState(null);
  const [formData, setFormData] = useState({
    enterpriseType: user?.company?.type ? user?.company?.type : '',
    enterpriseSize: user?.company?.workforce ? user?.company?.workforce : '',
    enterpriseCategory: user?.company?.field ? user?.company?.field : '',
    enterprisePhone: user?.company?.telephone ? user?.company?.telephone : '',
    enterpriseEmail: user?.company?.email ? user?.company?.email : '',
    enterpriseName: user?.company?.name ? user?.company?.name : '',
    enterpriseRut: user?.company?.rut ? user?.company?.rut : '',
    enterpriseFormalized: user?.company?.registerData?.established
      ? user?.company?.registerData?.established
      : '',
    enterpriseDescription: user?.company?.aboutUs ? user?.company?.aboutUs : '',
  });

  console.log('user', user);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onBack = () => {
    history.push('/usuarios');
  };

  const handleSubmitMentor = ({
    email,
    status,
    pillar = null,
    password = null,
    bio = '',
    linkedin = '',
  }) => {
    let payload = {};
    if (status === 'rejected') {
      payload = {
        email,
        status,
        password,
        bio,
        linkedin,
        pillar: null,
      };
    } else {
      payload = {
        email,
        status,
        password,
        bio,
        linkedin,
        pillar: pillar ? pillar.id : null,
      };
    }
    UsersAPI.updateUser(user.id, payload)
      .then((response) => {
        setMessageAlert({
          message: response.data.message,
          type: 'success',
        });
        setShowAlert(true);
      })
      .catch((e) => {
        setMessageAlert({ message: e.data.message, type: 'error' });
        setShowAlert(true);
      });
  };

  const handleSubmitAdmin = ({ email, status, password = null }) => {
    UsersAPI.updateUser(user.id, {
      email,
      status,
      password,
    })
      .then((response) => {
        setMessageAlert({
          message: response.data.message,
          type: 'success',
        });
        setShowAlert(true);
      })
      .catch((e) => {
        setMessageAlert({ message: e.data.message, type: 'error' });
        setShowAlert(true);
      });
  };

  const handleSubmitMentee = async (form) => {
    const payload = {
      companyTelephone: form.enterprisePhone,
      companyWorkforce: form.enterpriseSize,
      companyField: form.enterpriseCategory,
      companyType: form.enterpriseType,
    };

    await UsersAPI.updateUserNew(user.id, payload)
      .then((response) => {
        const [userResponse, _] = response;
        setMessageAlert({
          message: userResponse.data.message,
          type: 'success',
        });
        setShowAlert(true);
      })
      .catch((e) => {
        setMessageAlert({ message: e.data.message, type: 'error' });
        setShowAlert(true);
      });
  };

  const onSubmit = (values) => {
    if (user.role.type === 'mentee') {
      handleSubmitMentee(formData);
    } else if (user.role.type === 'mentor') {
      handleSubmitMentor({
        email,
        status,
        pillar,
        password,
        bio,
        linkedin,
      });
    } else {
      handleSubmitAdmin({ email, password, bio, linkedin, status });
    }
  };

  return (
    <div className="emprendimiento-container">
      <Grid container>
        <Grid
          container
          item
          xs={4}
          direction="column"
          wrap="nowrap"
          justifyContent="center"
          alignItems="center"
          style={{
            borderRight: '1px solid #0000001A ',
            borderBottom: '1px solid #0000001A ',
          }}
        >
          <Grid
            container
            item
            xs={8}
            justifyContent="center"
            alignItems="center"
            style={{ rowGap: '24px', paddingTop: '24px 0', margin: '32px 0' }}
          >
            <Grid item>
              <Avatar
                src={avatar}
                style={{
                  width: 157,
                  height: 157,
                  backgroundColor: '#F7F7F7',
                  border: '3px solid #DEDEDE',
                  color: '#CACACA',
                }}
                alt="Avatar"
              >
                {!avatar && (
                  <Storefront style={{ width: '60px', height: '60px' }} />
                )}
              </Avatar>
            </Grid>
            <Grid item xs={12}>
              <span style={{ fontSize: '18px' }}>
                <b>Características de la empresa</b>
              </span>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="enterpriseType"
                label="Tipo de empresa"
                value={formData.enterpriseType}
                onChange={handleChange}
                placeholder="Ingrese tipo de empresa"
                variant="outlined"
                className="textField-form"
                fullWidth
                InputProps={{
                  style: {
                    padding: '12px 8px', // Ajuste del padding interno
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="enterpriseSize"
                label="Tamaño de empresa"
                value={formData.enterpriseSize}
                onChange={handleChange}
                placeholder="Ingrese tamaño de empresa"
                variant="outlined"
                className="textField-form"
                fullWidth
                InputProps={{
                  style: {
                    padding: '12px 8px', // Ajuste del padding interno
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="enterpriseCategory"
                label="Rubro o sector"
                value={formData.enterpriseCategory}
                onChange={handleChange}
                placeholder="Ingrese rubro o sector"
                variant="outlined"
                className="textField-form"
                fullWidth
                InputProps={{
                  style: {
                    padding: '12px 8px', // Ajuste del padding interno
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="enterprisePhone"
                label="Teléfono de contacto"
                value={formData.enterprisePhone}
                onChange={handleChange}
                placeholder="Ingrese teléfono de contacto"
                variant="outlined"
                className="textField-form"
                fullWidth
                InputProps={{
                  style: {
                    padding: '12px 8px', // Ajuste del padding interno
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="enterpriseEmail"
                label="Email de contacto"
                value={formData.enterpriseEmail}
                onChange={handleChange}
                placeholder="Ingrese email de contacto"
                variant="outlined"
                className="textField-form"
                fullWidth
                InputProps={{
                  style: {
                    padding: '12px 8px', // Ajuste del padding interno
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={8}
          justifyContent="center"
          alignItems="flex-start"
          style={{
            borderBottom: '1px solid #0000001A ',
          }}
        >
          <Grid
            container
            item
            xs={10}
            style={{ rowGap: '24px', paddingTop: '24px 0', margin: '32px 0' }}
          >
            <Grid container item xs={5} style={{ rowGap: '12px' }}>
              <Grid item xs={12}>
                <span className="label">
                  <b>Nombre empresa</b>
                </span>
              </Grid>
              <Grid item xs={12}>
                <p className="data-label"> {formData.enterpriseName}</p>
              </Grid>
            </Grid>
            <Grid container item xs={3} style={{ rowGap: '12px' }}>
              <Grid item xs={12}>
                <span className="label">
                  <b>Rut</b>
                </span>
              </Grid>
              <Grid item xs={12}>
                <p className="data-label"> {format(formData?.enterpriseRut)}</p>
              </Grid>
            </Grid>
            <Grid container item xs={4} style={{ rowGap: '12px' }}>
              <Grid item xs={12}>
                <span className="label">
                  <b>Formalizado</b>
                </span>
              </Grid>
              <Grid item xs={12}>
                <p className="data-label">{formData.enterpriseFormalized}</p>
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ rowGap: '12px' }}>
              <Grid item xs={12}>
                <span className="label">
                  <b>Descripción</b>
                </span>
              </Grid>
              <Grid item xs={12}>
                <p className="data-label">{formData.enterpriseDescription}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        style={{
          padding: '24px',
          height: '200px',
          rowGap: '28px',
        }}
      >
        <Grid item>
          <Button secondary style={{ width: '241px' }} onClick={onBack}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button style={{ width: '241px' }} onClick={onSubmit}>
            Guardar información
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
