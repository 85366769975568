import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import Select from '../../../../components/forms/Select';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import Button from '../../../../components/common/Button';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import UsersAPI from '../../user-api';
import Alert from '../../../../components/common/Alert';
import Skeleton from 'react-placeholder';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../userContext';
import { Input } from '../../../../components/forms';
import ControlPointSharpIcon from '@material-ui/icons/ControlPointSharp';
import { FormSession } from './components/FormSession';
import { FormHorarios } from './components/FormHorarios';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0 none',
    padding: '40 0',
  },
}));

const SetSessions = () => {
  const history = useHistory();
  const location = useLocation();
  const { clientId } = location.state;
  const time = moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm');

  const [state, dispatch] = useContext(UserContext);
  const { user, mentors, futureSessions } = state;

  //set mentor options
  const mentorList = mentors.map((mentor) => ({
    id: mentor.id,
    value: mentor.name,
  }));

  const veces = Array.from({ length: 16 }, (x, i) => {
    if (i === 0) {
      return { value: `${i + 1} vez`, id: i + 1 };
    } else {
      return { value: `${i + 1} veces`, id: i + 1 };
    }
  });

  const dias = Array.from({ length: 15 }, (x, i) => {
    if (i === 0) {
      return { value: `${i + 1} dia`, id: i + 1 };
    } else {
      return { value: `${i + 1} dias`, id: i + 1 };
    }
  });
  const [message, setMessage] = useState({});
  const [messageDates, setMessageDates] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [initialHour, setInitialHour] = useState(null);
  const [initialDate, setInitialDate] = useState(
    moment(time).format('YYYY-MM-DD')
  );
  const [initialTime, setInitialTime] = useState(time);
  const [inputVeces, setInputVeces] = useState(0);
  const [inputDias, setInputDias] = useState(0);
  const [mentor, setMentor] = useState(0);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [calculated, setCalculated] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [submit1, setSubmit1] = useState(false);
  const [guestEmails, setGuestEmails] = useState([]);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
  const [openModal, setOpenModal] = useState(false);
  const [inputInvitado, setInputInvitado] = useState('');
  const classes = useStyles();

  //schedule resume
  const initialSchedules = () => {
    setMessageDates([]);
    setCalculated(true);
    if (validation()) {
      const schedulesArray = Array.from({ length: inputVeces }, (x, i) => {
        if (!i) {
          return moment(initialTime).format('YYYY-MM-DD HH:mm');
        } else {
          return moment(initialTime)
            .add(parseInt(inputDias * i), 'days')
            .format('YYYY-MM-DD HH:mm');
        }
      });
      dispatch({
        type: 'UPDATE_FUTURE_SESSIONS',
        payload: {
          futureSessions: {
            schedules: schedulesArray,
            userId: user.id,
            mentorId: mentor,
            subject,
            description,
            notify: false,
            guestEmails,
          },
        },
      });

      UsersAPI.getBusySchedule(mentor, user.id).then((response) => {
        schedulesArray.forEach((schedule, index) => {
          response.data.forEach((date) => {
            if (
              moment(schedule).format('YYYY-MM-DD HH:mm') ===
              moment(date.date).format('YYYY-MM-DD HH:mm')
            ) {
              let messageD = messageDates;
              messageD[index] = date.message;
              setMessageDates(messageD);
            }
          });
        });
        setCalculated(false);
      });
    }
  };

  const validation = () => {
    setMessage({});
    const actualDate = moment().subtract(1, 'hour');
    let validate = true;
    let validateMessage = {};
    console.log('initialTime', initialTime);
    if (initialTime) {
      if (!moment(initialTime).isAfter(actualDate.format('YYYY-MM-DDTHH:mm'))) {
        validate = false;
        validateMessage.initialTime =
          'La fecha seleccionada no puede ser anterior a la fecha actual';
        setMessage(validateMessage);
      }
    }
    if (!initialHour) {
      validate = false;
      validateMessage.initialHour = 'Ingrese Hora';
      setMessage(validateMessage);
    }
    if (!initialDate) {
      validate = false;
      validateMessage.initialDate = 'Ingrese Fecha';
      setMessage(validateMessage);
    }
    if (mentor === 0) {
      validate = false;
      validateMessage.mentor = 'Debe seleccionar un mentor';
      setMessage(validateMessage);
    }
    if (inputVeces === 0) {
      validate = false;
      validateMessage.inputVeces = 'Valor Requerido';
      setMessage(validateMessage);
    }
    if (inputDias === 0) {
      validate = false;
      validateMessage.inputDias = 'Valor Requerido';
      setMessage(validateMessage);
    }
    if (subject.localeCompare('') === 0) {
      validate = false;
      validateMessage.subject = 'Debe ingresar asunto';
      setMessage(validateMessage);
    }
    if (description.localeCompare('') === 0) {
      validate = false;
      validateMessage.description = 'Ingrese una pequeña descripción';
      setMessage(validateMessage);
    }
    if (!validate) {
      setCalculated(false);
    }
    return validate;
  };

  const handleSubmit = (notify) => {
    if (validation() && !messageDates.length) {
      if (notify) {
        setSubmit(true);
      } else {
        setSubmit1(true);
      }

      UsersAPI.createMassiveSessions({
        calendar: futureSessions.schedules,
        idMentee: user.id,
        idMentor: mentor,
        title: subject,
        description,
        notify,
        clientId,
        guestEmails,
      })
        .then((response) => {
          dispatch({
            type: 'UPDATE_FUTURE_SESSIONS',
            payload: {
              futureSessions: {},
            },
          });
          setAlertMessage({ type: 'success', message: response.data.message });
          setOpenModal(false);
        })
        .catch((e) => {
          setAlertMessage({ type: 'error', message: e.data.message });
        })
        .finally(() => {
          setSubmit(false);
          setSubmit1(false);
        });
    }
  };

  const changeInitialTime = (date, time) => {
    console.log('date', date);
    let oldTime = initialTime.split(' ')[1];
    if (!time) {
      if (oldTime) {
        setInitialTime(date + ' ' + oldTime);
      } else {
        setInitialTime(date + ' 00:00');
      }
      setInitialDate(date);
    } else {
      setInitialHour(time);
      setInitialTime(moment(initialTime).format('YYYY-MM-DD') + ' ' + time);
    }
  };
  const addGuest = (values) => {
    try {
      if (guestEmails.length < 4 && values && !guestEmails.includes(values)) {
        setGuestEmails([...guestEmails, values]);
        setInputInvitado('');
      } else if (guestEmails.length >= 4) {
        setAlertMessage(
          'Se ha alcanzado el límite máximo de correos electrónicos.'
        );
        setAlertType('error');
        setTimeout(() => {
          setAlertMessage('');
        }, 3000);
      }
    } catch (error) {}
  };
  const removeGuestEmail = (index) => {
    const updatedGuestEmails = [...guestEmails];
    updatedGuestEmails.splice(index, 1);
    setGuestEmails(updatedGuestEmails);
  };
  const changeIndividualTime = (key, date, time) => {
    let schedulesArray = futureSessions.schedules;
    const actualDate = moment().subtract(1, 'hour');
    let newDate = '';
    let oldDate = schedulesArray[key].split(' ')[0];
    let oldTime = schedulesArray[key].split(' ')[1];
    if (date) {
      schedulesArray[key] = moment(date).format('YYYY-MM-DD') + ' ' + oldTime;
      newDate = moment(date).format('YYYY-MM-DD') + ' ' + oldTime;
    }
    if (time) {
      schedulesArray[key] = moment(oldDate).format('YYYY-MM-DD') + ' ' + time;
      newDate = moment(oldDate).format('YYYY-MM-DD') + ' ' + time;
    }
    let messageD = [];
    if (!moment(newDate).isAfter(actualDate.format('YYYY-MM-DDTHH:mm'))) {
      messageD[key] = 'La fecha ingresada no puede ser menor a la actual.';
    }
    UsersAPI.getBusySchedule(mentor, user.id).then((response) => {
      schedulesArray.forEach((schedule, index) => {
        response.data.forEach((date) => {
          if (
            moment(schedule).format('YYYY-MM-DD HH:mm') ===
            moment(date.date).format('YYYY-MM-DD HH:mm')
          ) {
            messageD[index] = date.message;
          }
        });
      });
    });
    setMessageDates(messageD);
    setSchedules(schedulesArray);
    dispatch({
      type: 'UPDATE_FUTURE_SESSIONS',
      payload: {
        futureSessions: {
          schedules: schedulesArray,
          userId: user.id,
          mentorId: mentor,
          subject,
          description,
          notify: false,
        },
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        mentorId: mentor,
        dias: inputDias,
        veces: inputVeces,
      }}
      validationSchema={Yup.object({
        mentor: Yup.string().required(
          'Seleccione mentor para asignar reuniones'
        ),
        guestEmail: Yup.string().required('Ingrese un correo electrónico'),
      })}
    >
      {({ values }) => (
        <Form>
          {openModal && (
            <Modal
              className={classes.modal}
              open={openModal}
              onClose={() => {
                setOpenModal(false);
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className="cancel-session notificacion">
                <h3>¿Desea notificar a los participantes?</h3>
                <div className="info__button">
                  <div className="save-area">
                    <Grid container justify="space-around">
                      <Grid item xs={5}>
                        <Button
                          widthFull
                          secondary
                          loading={submit1}
                          onClick={() => {
                            handleSubmit(false);
                          }}
                        >
                          No, gracias
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          widthFull
                          type="submit"
                          onClick={() => handleSubmit(true)}
                          loading={submit}
                        >
                          Si
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {mentorList.length === 0 ? (
            <Grid container>
              <Grid item xs={12}>
                <Skeleton showLoadingAnimation type="text" rows={15} />
              </Grid>
            </Grid>
          ) : (
            <div className="container-future-sessions">
              <Grid
                container
                xs={12}
                style={{
                  borderBottom: '1px solid #0000001A ',
                  height: 'inherit',
                }}
              >
                <FormSession
                  alertMessage={alertMessage}
                  mentorList={mentorList}
                  message={message}
                  setMentor={setMentor}
                  description={description}
                  setDescription={setDescription}
                  setSubject={setSubject}
                  subject={subject}
                  guestEmails={guestEmails}
                  inputInvitado={inputInvitado}
                  setInputInvitado={setInputInvitado}
                  addGuest={addGuest}
                  removeGuestEmail={removeGuestEmail}
                />
                <FormHorarios
                  initialTime={initialTime}
                  changeInitialTime={changeInitialTime}
                  message={message}
                  setInputVeces={setInputVeces}
                  veces={veces}
                  inputVeces={inputVeces}
                  setInputDias={setInputDias}
                  dias={dias}
                  initialSchedules={initialSchedules}
                  calculated={calculated}
                  futureSessions={futureSessions}
                  changeIndividualTime={changeIndividualTime}
                  messageDates={messageDates}
                />
              </Grid>

              <Grid container style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item xs={4}>
                      <Button
                        onClick={() => history.push('/usuarios')}
                        secondary
                        widthFull
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        onClick={() => {
                          futureSessions.schedules.length && setOpenModal(true);
                        }}
                        primary
                        widthFull
                        disabled={Object.keys(futureSessions).length === 0}
                      >
                        Confirmar Reuniones
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SetSessions;
