import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close, ExpandMoreOutlined, Today } from '@material-ui/icons';
import ControlPointSharpIcon from '@material-ui/icons/ControlPointSharp';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

export const HorariosList = ({
  listSessions,
  messageDates,
  changeIndividualTime,
  optiosHours,
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      alignItems="center"
      style={{
        rowGap: '24px',
        margin: '17px 0',
        borderTop: '1px solid #0000001A ',
        height: 'inherit',
      }}
    >
      <Grid
        item
        container
        xs={10}
        style={{ paddingTop: '24px', rowGap: '24px' }}
      >
        <Grid item xs={12}>
          <span style={{ fontSize: '16px' }}>
            <b>Fecha y hora programada</b>
          </span>
        </Grid>
        {listSessions.length > 0 &&
          listSessions.map((schedule, key) => {
            return (
              <Grid container item key={key} style={{ gap: '24px' }}>
                <Grid item xs={2}>
                  <h4>Sesión {key + 1}</h4>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="date"
                    type="date"
                    defaultValue={moment(schedule.split(' ')[0]).format(
                      'YYYY-MM-DD'
                    )}
                    onChange={(event) => {
                      changeIndividualTime(
                        key,
                        moment(event.target.value).format('YYYY-MM-DD'),
                        null
                      );
                    }}
                    fullWidth
                    className="textFieldPicker-form"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  {messageDates[key] && (
                    <div className="error-message error-grid">
                      {messageDates[key]}
                    </div>
                  )}
                </Grid>
                <Grid item xs={2} className="hour-grid">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="textField-form"
                  >
                    <InputLabel id="custom-select-label">Hora</InputLabel>
                    <Select
                      labelId="custom-select-label"
                      name="hora"
                      value={schedule.split(' ')[1]}
                      onChange={(event) => {
                        changeIndividualTime(
                          key,
                          null,
                          event.target.value + ':00'
                        );
                      }}
                      label="Hora"
                      MenuProps={{
                        shrink: true,
                        classes: { paper: 'selectMenu' },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        PaperProps: {
                          style: {
                            marginTop: 8,
                          },
                        },
                      }}
                      IconComponent={ExpandMoreOutlined}
                    >
                      {optiosHours.map((option) => {
                        if (option.id !== undefined) {
                          return (
                            <MenuItem key={option.value} value={option.id}>
                              {option.value}
                            </MenuItem>
                          );
                        } else {
                          return (
                            <MenuItem key={option.value} value={option.value}>
                              {option.value}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};
