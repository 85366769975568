import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import {
  Cancel,
  ClearOutlined,
  Edit,
  ExpandMoreOutlined,
} from '@material-ui/icons';
import AvatarUser from './Avatar';
import { capitalizingPhrase, formatDate } from '../../../../../utils/format';
import { useRut } from '../../../../../utils/formatRut';
import Button from '../../../../../components/common/Button';
import moment from 'moment';
import {
  optionsCities,
  optionsCountry,
  optionsRegion,
} from '../../../../../utils/paises-ciudades-regiones';

const useStyles = makeStyles((theme) => ({
  locationSection: {
    rowGap: '24px',
    padding: '24px 0',
  },
  selectMenu: {
    maxHeight: '300px', // This will limit the dropdown height
    overflowY: 'auto', // Enable scrolling if content exceeds maxHeight
  },
  formControl: {
    marginBottom: '24px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
  },
}));

export const InfoMentees = ({ formData, setFormData, pillarsOptions }) => {
  const classes = useStyles();
  const { format } = useRut('');
  const [optionsCountries, setOptionsCountries] = useState([]);
  const [optionsRegions, setOptionsRegions] = useState([]);
  const [optionsCity, setOptionsCity] = useState([]);

  const fetchCountries = async () => {
    const countries = await optionsCountry();
    setOptionsCountries(countries);
  };

  const fetchRegions = useCallback(async () => {
    if (Object.values(formData.country).length > 0) {
      const regions = await optionsRegion(formData.country);
      setOptionsRegions(regions);
    }
  }, [formData.country]);

  const fetchCities = useCallback(async () => {
    if (Object.values(formData.region).length > 0) {
      const cities = await optionsCities(formData.country, formData.region);
      setOptionsCity(cities);
    }
  }, [formData.region]);

  useEffect(() => {
    fetchCountries();
    fetchRegions();
    fetchCities();
  }, []);

  console.log('formData', formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeChecked = (e) => {
    const { checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      active: checked, // Actualiza el estado con el nombre y el valor del switch
    }));
  };

  const handleDeleteChip = (chipToDelete, event) => {
    event.stopPropagation();
    setFormData((prevData) => {
      const updatedPillars = prevData.pillars.filter(
        (value) => value !== chipToDelete
      );
      console.log('Updated formData pillars:', updatedPillars);
      return {
        ...prevData,
        pillars: updatedPillars,
      };
    });
  };

  const menteeAdvancedOptions = [
    { id: 'active', value: 'Activo' },
    { id: 'inactive', value: 'Inactivo' },
    { id: 'deactivated', value: 'Desactivado' },
  ];

  const statusRegister = useMemo(() => {
    const filter = menteeAdvancedOptions.filter(
      (item) => item.id === formData.status
    );

    return filter[0].value;
  }, [formData]);

  const selectStyles = {
    marginBottom: '24px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
  };

  return (
    <form noValidate autoComplete="off">
      <div className="container-info-mentee">
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={1}
          className="resumen-mentee"
        >
          <Grid item xs={4}>
            <AvatarUser user={formData} />
          </Grid>
          <Grid container item xs={8} spacing={5} style={{ rowGap: '24px' }}>
            <Grid container spacing={1} alignItems="center" xs={12}>
              <Grid item>
                <span className="title-resumen">
                  <b>Resumen</b>
                </span>
              </Grid>
              <Grid item>
                <Chip className="chip-resumen" label={formData.role} />
              </Grid>
            </Grid>
            <Grid container xs={5} style={{ rowGap: '12px' }}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <span className="label">
                    <b>Nombre</b>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <p className="data-label"> {formData.name}</p>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <span className="label">
                    <b>Estado de registro</b>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <p className="data-label"> {statusRegister}</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={3} style={{ rowGap: '12px' }}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <span className="label">
                    <b>Rut</b>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <p className="data-label"> {formData?.rut}</p>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <span className="label">
                    <b>Última conexión</b>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <p className="data-label">{formData.lastSession}</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={4} style={{ rowGap: '28px' }}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <span className="label">
                    <b>Fecha de enrolamiento</b>
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <p className="data-label">{formData.enrollment}</p>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={1}
              >
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={formData.active}
                      onChange={handleChangeChecked}
                    />
                  }
                  label={<b>Usuario activo</b>}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: '4px' }} />
        <Grid container xs={12} spacing={1}>
          <Grid
            container
            item
            xs={6}
            direction="column"
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
            style={{
              borderRight: '1px solid #0000001A ',
              borderBottom: '1px solid #0000001A ',
            }}
          >
            <Grid
              container
              item
              xs={6}
              style={{ rowGap: '24px', paddingTop: '24px 0', margin: '32px 0' }}
            >
              <Grid item xs={12}>
                <span style={{ fontSize: '18px' }}>
                  <b>Datos personales</b>
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="birthdate"
                  label="Fecha de nacimiento"
                  value={formData.birthdate}
                  onChange={handleChange}
                  placeholder="DD/MM/AAAA"
                  variant="outlined"
                  className="textField-form"
                  fullWidth
                  InputProps={{
                    style: {
                      padding: '12px 8px', // Ajuste del padding interno
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="gender"
                  label="Genero"
                  value={formData.gender}
                  onChange={handleChange}
                  placeholder="Ingrese su genero"
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                  InputProps={{
                    style: {
                      padding: '12px 8px', // Ajuste del padding interno
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="E-mail"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="ejemplo@chileconverge.cl"
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                  InputProps={{
                    style: {
                      padding: '12px 8px', // Ajuste del padding interno
                    },
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phone"
                  label="Teléfono de contacto"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="56912345678"
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                  InputProps={{
                    style: {
                      padding: '12px 8px', // Ajuste del padding interno
                    },
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="linkedin"
                  label="Enlace a perfíl de Linkedin"
                  value={formData.linkedin}
                  onChange={handleChange}
                  placeholder="Ingrese su enlace"
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                  InputProps={{
                    style: {
                      padding: '12px 8px',
                      autoComplete: 'off',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid
              container
              item
              xs={6}
              style={{ rowGap: '24px', paddingTop: '24px 0', margin: '32px 0' }}
            >
              <Grid item xs={12}>
                <span style={{ fontSize: '18px' }}>
                  <b>Contraseña</b>
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  label="Contraseña"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Contraseña"
                  variant="outlined"
                  type="password"
                  fullWidth
                  className="textField-form"
                  InputProps={{
                    style: {
                      padding: '12px 8px',
                      autoComplete: 'new-password',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <p style={{ fontSize: '12px', opacity: 0.5 }}>
                  Las políticas de seguridad para crear una contraseña son:
                </p>
                <ul>
                  <li style={{ fontSize: '12px', opacity: 0.5 }}>
                    De 6 a 8 caracteres.
                  </li>
                  <li style={{ fontSize: '12px', opacity: 0.5 }}>
                    Al menos 2 letras.
                  </li>
                  <li style={{ fontSize: '12px', opacity: 0.5 }}>
                    Al menos 2 números.
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
            style={{
              borderBottom: '1px solid #0000001A ',
              height: 'inherit',
            }}
          >
            <Grid
              container
              item
              xs={6}
              style={{ rowGap: '24px', margin: '17px 0' }}
            >
              <Grid item xs={12}>
                <span style={{ fontSize: '18px' }}>
                  <b>Datos adicionales</b>
                </span>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="education"
                  label="Nivel educacional"
                  value={formData.education}
                  onChange={handleChange}
                  placeholder=""
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                  InputProps={{
                    style: {
                      padding: '12px 8px', // Ajuste del padding interno
                    },
                    readOnly: true,
                  }}
                />
                {/* <FormControl
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                >
                  <InputLabel id="custom-select-label">
                    Nivel educacional
                  </InputLabel>
                  <Select
                    labelId="custom-select-label"
                    name="education"
                    disabled
                    value={formData.education}
                    onChange={handleChange}
                    label="Nivel educacional"
                    MenuProps={{
                      classes: { paper: 'selectMenu' },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          marginTop: 8,
                        },
                      },
                    }}
                    IconComponent={ExpandMoreOutlined}
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    <MenuItem value={10}>Opción 1</MenuItem>
                    <MenuItem value={20}>Opción 2</MenuItem>
                    <MenuItem value={30}>Opción 3</MenuItem>
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                >
                  <InputLabel id="custom-select-label">Discapacidad</InputLabel>
                  <Select
                    labelId="custom-select-label"
                    name="disability"
                    value={formData.disability}
                    onChange={handleChange}
                    label="Discapacidad"
                    MenuProps={{
                      classes: { paper: 'selectMenu' },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          marginTop: 8,
                        },
                      },
                    }}
                    IconComponent={ExpandMoreOutlined}
                  >
                    <MenuItem value={'true'}>Sí</MenuItem>
                    <MenuItem value={'false'}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                >
                  <InputLabel id="custom-select-label">
                    Registro social
                  </InputLabel>
                  <Select
                    name="registroSocial"
                    labelId="custom-select-label"
                    value={formData.registroSocial}
                    onChange={handleChange}
                    label="Registro social"
                    MenuProps={{
                      classes: { paper: 'selectMenu' },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          marginTop: 8,
                        },
                      },
                    }}
                    IconComponent={ExpandMoreOutlined}
                  >
                    <MenuItem value={'40%'}>40 %</MenuItem>
                    <MenuItem value={'50%'}>50 %</MenuItem>
                    <MenuItem value={'60%'}>60 %</MenuItem>
                    <MenuItem value={'70% o más'}>70 % o más</MenuItem>
                    <MenuItem value={'No estoy inscrito'}>No estoy inscrito</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                >
                  <InputLabel id="custom-select-label">Región</InputLabel>
                  <Select
                    name="region"
                    labelId="custom-select-label"
                    value={formData.region}
                    onChange={handleChange}
                    label="Región"
                    MenuProps={{
                      classes: { paper: 'selectMenu' },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          marginTop: 6,
                        },
                      },
                    }}
                    IconComponent={ExpandMoreOutlined}
                  >
                    {optionsRegions.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="textField-form" // Matches the className from Región
                >
                  <InputLabel id="city-label">Ciudad</InputLabel>
                  <Select
                    labelId="city-label"
                    name="city"
                    value={formData.city || ''}
                    onChange={handleChange}
                    label="Ciudad"
                    IconComponent={ExpandMoreOutlined}
                    disabled={!formData.region || !optionsCity.length}
                    MenuProps={{
                      classes: { paper: 'selectMenu' }, // Matches the class name from Región
                      getContentAnchorEl: null, // Added to match Región
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          marginTop: 6, // Matches the marginTop from Región
                        },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Seleccione una ciudad</em>
                    </MenuItem>
                    {optionsCity.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                >
                  <InputLabel id="custom-select-label">País</InputLabel>
                  <Select
                    labelId="custom-select-label"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    label="País"
                    MenuProps={{
                      classes: { paper: 'selectMenu' },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          marginTop: 6,
                        },
                      },
                    }}
                    IconComponent={ExpandMoreOutlined}
                  >
                    {optionsCountries.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid
              container
              item
              xs={6}
              style={{ rowGap: '24px', paddingTop: '24px 0', margin: '32px 0' }}
            >
              <Grid item xs={12}>
                <span style={{ fontSize: '18px' }}>
                  <b>Pilares de mentoría</b>
                </span>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="textField-form"
                >
                  {/* <InputLabel id="custom-select-label">País</InputLabel> */}
                  <Select
                    name="pillars"
                    className="select-multiple"
                    labelId="multiple-select-labe"
                    value={formData.pillars}
                    onChange={handleChange}
                    multiple
                    renderValue={(selected) => (
                      <div className={'chips-container'}>
                        {selected.slice(0, 1).map((value) => {
                          const option = pillarsOptions.find(
                            (option) => option.id === value
                          );
                          const label = option?.name;
                          console.log('selected', selected.length === 1);
                          return (
                            <Chip
                              key={value}
                              label={label}
                              className={`chips ${
                                selected.length === 1 ? 'more-options' : ''
                              }`}
                              // deleteIcon={
                              //   <IconButton
                              //     size="small"
                              //     className={'delete-button'}
                              //     onMouseDown={(event) =>
                              //       handleDeleteChip(value, event)
                              //     }
                              //     disabled
                              //   >
                              //     <ClearOutlined fontSize="small" />
                              //   </IconButton>
                              // }
                              // onDelete={(event) =>
                              //   handleDeleteChip(value, event)
                              // }
                            />
                          );
                        })}
                        {selected.length > 1 && (
                          <Chip
                            label={`+ ${selected.length - 1} más`}
                            className={'chips more-options'}
                          />
                        )}
                      </div>
                    )}
                    MenuProps={{
                      classes: { paper: 'selectMenu' }, // Aquí usamos la clase de SCSS
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: {
                        style: {
                          marginTop: 160,
                          pointerEvents: 'none',
                        },
                      },
                    }}
                    maxTagCount={1}
                    IconComponent={ExpandMoreOutlined}
                  >
                    {pillarsOptions.map((option) => (
                      <MenuItem value={option.id}>{option.name}</MenuItem>
                    ))}
                    {/* <MenuItem value="Opción 1">Opción 1</MenuItem>
                    <MenuItem value="Opción 2">Opción 2</MenuItem>
                    <MenuItem value="Opción 3">Opción 3</MenuItem>
                    <MenuItem value="Opción 4">Opción 4</MenuItem>
                    <MenuItem value="Opción 5">Opción 5</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
