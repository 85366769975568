import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close, ExpandMoreOutlined, Today } from '@material-ui/icons';
import ControlPointSharpIcon from '@material-ui/icons/ControlPointSharp';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { useMemo } from 'react';
import { HorariosList } from './HorariosList';
import { Skeleton } from 'survey-react-ui';

export const FormHorarios = ({
  initialTime,
  changeInitialTime,
  message,
  inputVeces,
  setInputVeces,
  veces,
  setInputDias,
  dias,
  initialSchedules,
  calculated,
  futureSessions,
  changeIndividualTime,
  messageDates,
}) => {
  const optiosHours = [
    { id: '08:00', value: '08:00' },
    { id: '09:00', value: '09:00' },
    { id: '10:00', value: '10:00' },
    { id: '11:00', value: '11:00' },
    { id: '12:00', value: '12:00' },
    { id: '13:00', value: '13:00' },
    { id: '14:00', value: '14:00' },
    { id: '15:00', value: '15:00' },
    { id: '16:00', value: '16:00' },
    { id: '17:00', value: '17:00' },
    { id: '18:00', value: '18:00' },
    { id: '19:00', value: '19:00' },
    { id: '20:00', value: '20:00' },
    { id: '21:00', value: '21:00' },
  ];

  const listSessions = useMemo(() => {
    if (!futureSessions.schedules) return [];
    return futureSessions.schedules;
  }, [futureSessions.schedules]);

  return (
    <Grid container item xs={8} justifyContent="center">
      <Grid
        container
        item
        xs={10}
        alignContent="flex-start"
        style={{ rowGap: '24px', marginBottom: '17px', marginTop: '88px' }}
      >
        <Grid item xs={12}>
          <span style={{ fontSize: '16px' }}>
            <b>Horarios</b>
          </span>
          <p style={{ fontSize: '14px', color: '#25282B', opacity: '50%' }}>
            Seleccionar fecha, hora y frecuencia de las reuniones
          </p>
        </Grid>
        <Grid item container xs={12} style={{ gap: '24px' }}>
          <Grid item xs={3}>
            <TextField
              id="date"
              type="date"
              defaultValue={moment(initialTime).format('YYYY-MM-DD')}
              onChange={(event) => {
                changeInitialTime(
                  moment(event.target.value).format('YYYY-MM-DD'),
                  ''
                );
              }}
              fullWidth
              className="textFieldPicker-form"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {message.initialDate && (
              <div className="error-message" style={{ marginTop: '20px' }}>
                {message.initialDate}
              </div>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControl
              variant="outlined"
              fullWidth
              className="textField-form"
            >
              <InputLabel id="custom-select-label">Hora</InputLabel>
              <Select
                labelId="custom-select-label"
                name="hora"
                onChange={(event) => {
                  changeInitialTime('', event.target.value + ':00');
                }}
                label="Hora"
                MenuProps={{
                  shrink: true,
                  classes: { paper: 'selectMenu' },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  PaperProps: {
                    style: {
                      marginTop: 8,
                    },
                  },
                }}
                IconComponent={ExpandMoreOutlined}
              >
                {optiosHours.map((option) => {
                  if (option.id !== undefined) {
                    return (
                      <MenuItem key={option.value} value={option.id}>
                        {option.value}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
            {message.initialHour && (
              <div className="error-message">{message.initialHour}</div>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControl
              variant="outlined"
              fullWidth
              className="textField-form"
            >
              <InputLabel id="custom-select-label">Repetir</InputLabel>
              <Select
                labelId="custom-select-label"
                name="veces"
                onChange={(event) => setInputVeces(event.target.value)}
                label="Repetir"
                MenuProps={{
                  shrink: true,
                  classes: { paper: 'selectMenu' },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  PaperProps: {
                    style: {
                      marginTop: 8,
                    },
                  },
                }}
                IconComponent={ExpandMoreOutlined}
              >
                {veces.map((option) => {
                  if (option.id !== undefined) {
                    return (
                      <MenuItem key={option.value} value={option.id}>
                        {option.value}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
              {message.inputVeces && (
                <div className="error-message">{message.inputVeces}</div>
              )}
            </FormControl>
          </Grid>
          {inputVeces > 0 && (
            <Grid item xs={3}>
              <FormControl
                variant="outlined"
                fullWidth
                className="textField-form"
              >
                <InputLabel id="custom-select-label">Cada</InputLabel>
                <Select
                  labelId="custom-select-label"
                  name="dias"
                  onChange={(event) => {
                    setInputDias(event.target.value);
                  }}
                  label="Cada"
                  MenuProps={{
                    shrink: true,
                    classes: { paper: 'selectMenu' },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: {
                      style: {
                        marginTop: 8,
                      },
                    },
                  }}
                  IconComponent={ExpandMoreOutlined}
                >
                  {dias.map((option) => {
                    if (option.id !== undefined) {
                      return (
                        <MenuItem key={option.value} value={option.id}>
                          {option.value}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                {message.inputDias && (
                  <div className="error-message">{message.inputDias}</div>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid item xs={3}>
            <div className="container-btn-horarios">
              <Button
                onClick={() => initialSchedules()}
                primary
                widthFull
                loading={calculated}
                startIcon={<Today />}
              >
                <u>Ver Horarios</u>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {!calculated && listSessions.length > 0 ? (
        <HorariosList
          listSessions={listSessions}
          messageDates={messageDates}
          changeIndividualTime={changeIndividualTime}
          optiosHours={optiosHours}
        />
      ) : (
        <Skeleton animation="wave" />
      )}
    </Grid>
  );
};
