import React, { Fragment, useEffect, useState } from 'react';
import MaUTable from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '../../common/Button';
import ButtonMui from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import empty from '../../../assets/images/empty.png';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Search from './searchBox';
import { TablePagination } from '@material-ui/core';
import RangeDatePicker from '../RangeDatePicker';
import { RestoreFromTrash } from '@material-ui/icons';
import { TablePaginationActions } from './TablePaginationActions';
import { RowsExpanded } from './RowsExpanded';
import EmptyCard from '../Cards/emptyCard';

const LIMIT_PER_PAGE = 25;

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setPage,
  onSearch,
  style,
  value,
  setValue,
}) {
  const count = preGlobalFilteredRows.length;
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 600);

  const onClick = (inputvalue) => {
    onSearch(inputvalue);
    setGlobalFilter(inputvalue);
    setPage(1);
  };

  const search = (e) => {
    setValue(e.target.value);
    if (e.target.value === '') {
      onClick(e.target.value);
    }
  };

  return (
    <span>
      <Search
        style={style}
        value={value}
        onChange={(e) => {
          if (onSearch) {
            search(e);
          } else {
            setValue(e.target.value);
            onChange(e.target.value);
            setPage(0);
          }
        }}
        onClick={() => onClick(value)}
      />
    </span>
  );
}

const EnhancedTable = ({
  columns,
  data,
  setData,
  tableName,
  loader,
  updateMyData,
  skipPageReset,
  onClickButton,
  buttonLabel,
  isLoadingData,
  currentPage,
  setCurrentPage,
  setIsLoading,
  totalPages,
  onSearch,
  setDateRangeSelected,
  dateRangeSelected,
  resetFilter,
  filter,
  style,
  isDynamic = false,
  haveRowSelect = false,
  onRelationSurvey,
  expanded,
  setExpanded,
  rowSelected = [],
  setRowSelected,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      updateMyData: (rowIndex, columnId, value) => {
        const newData = [...data];
        newData[rowIndex][columnId] = value;
        setData(newData);
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const [rowsPerPage, setRowsPerPage] = useState(
    isDynamic ? data.length : LIMIT_PER_PAGE
  );
  const [value, setValue] = React.useState('');
  const isSelected = (id) => rowSelected.includes(id);

  const handleSelectAllClick = () => {
    if (rowSelected.length !== rows.length) {
      const newSelecteds = rows.map((row) => row.original.idEncuesta);
      setRowSelected(newSelecteds);
    } else {
      setRowSelected([]);
    }
  };

  const toggleRowExpanded = (rowIndex) => {
    setExpanded((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    setRowSelected((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(id);
      if (isAlreadySelected) {
        // Si ya está seleccionado, lo quitamos
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // Si no está seleccionado, lo agregamos
        return [...prevSelected, id];
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.error': { backgroundColor: '#FFF3F2' },
      '&.info': { backgroundColor: '#F1FCFF' },
      '&.success': { backgroundColor: '#EBFDF0' },
      '&.warning': { backgroundColor: '#FFFBEE' },
      '&.default': { backgroundColor: '#FFFFFF' },
    },
  }))(TableRow);

  const onResetFilter = () => {
    resetFilter();
    setGlobalFilter(undefined);
    setValue('');
    setRowSelected([]);
  };

  return (
    <TableContainer className="table-container">
      <div className="actions-table">
        <h1 className="actions-table__title">{tableName}</h1>
        <div className="actions-table__action-container">
          {tableName === 'Encuestas' && haveRowSelect && (
            <ButtonMui
              variant="text"
              disabled={rowSelected.length === 0}
              className={`__btnLink ${rowSelected.length === 0 && 'disabled'}`}
              onClick={() => onRelationSurvey(rowSelected)}
            >
              Relacionar encuestas
            </ButtonMui>
          )}
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            setPage={setCurrentPage}
            onSearch={onSearch}
            style={style}
            setValue={setValue}
            value={value}
          />
          {tableName === 'Encuestas' && (
            <RangeDatePicker
              dateRange={dateRangeSelected}
              setDateRange={setDateRangeSelected}
            />
          )}
          {loader && <CircularProgress color="secondary" />}
          {onClickButton && (
            <Button
              className="actions-table__button button"
              type={'button'}
              onClick={onClickButton}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      </div>
      <div className="delete-filter-container">
        {Object.values(filter).length > 0 && (
          <ButtonMui
            startIcon={<RestoreFromTrash />}
            className="_btn"
            variant="text"
            onClick={onResetFilter}
          >
            <u>Borrar filtros</u>
          </ButtonMui>
        )}
      </div>

      {isLoadingData || data.length ? (
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {haveRowSelect && (
                  <TableCell
                    padding="checkbox"
                    style={{ minWidth: '50px', width: '50px' }}
                  >
                    <Checkbox
                      className="checkbox-check"
                      indeterminate={
                        rowSelected.length > 0 &&
                        rowSelected.length < rows.length
                      }
                      checked={
                        rows.length > 0 && rowSelected.length === rows.length
                      }
                      onClick={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all rows' }}
                    />
                  </TableCell>
                )}
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...(column.id === 'selection'
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                    style={{ width: column.width, minWidth: column.width }}
                  >
                    {column.render('Header')}
                    {column.id !== 'selection' && (
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {isLoadingData
              ? [1, 2, 3, 4, 5].map((number) => (
                  <TableRow key={number + Math.random().toString()}>
                    {headerGroups[0].headers.map(() => (
                      <TableCell key={Math.random().toString()}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : isDynamic
              ? rows.map((row, i) => {
                  prepareRow(row);

                  const rowId =
                    row.original.rowGroup.length >= 1
                      ? row.original.rowGroup.map((item) => item.id).join(', ')
                      : row.original.idEncuesta;

                  const isItemSelected = isSelected(rowId);

                  return (
                    <Fragment key={row?.id}>
                      <StyledTableRow
                        className={`${row.original?.bgColor} ${
                          row.original?.estado ? '' : 'inactive'
                        }`}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        {...row.getRowProps()}
                      >
                        {haveRowSelect && (
                          <TableCell
                            padding="checkbox"
                            style={{ minWidth: '50px', width: '50px' }}
                          >
                            <Checkbox
                              id={`checkbox-${row?.id}`} // ID único para cada checkbox
                              onClick={(event) =>
                                handleClick(event, rowId, row)
                              }
                              className="checkbox-check"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': `label-${row?.id}`,
                              }}
                            />
                          </TableCell>
                        )}
                        {row.cells.map((cell) => (
                          <TableCell
                            {...cell.getCellProps()}
                            style={{
                              width: cell.column.width,
                              minWidth: cell.column.width,
                            }}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        ))}
                      </StyledTableRow>
                      {expanded[row.original.rowIndex] && (
                        <TableRow
                          style={{ boxShadow: '8px 6px 7px 0px #101E730F' }}
                        >
                          <TableCell
                            colSpan={columns.length + 1}
                            style={{
                              padding: 0,
                              borderBottom: '1px solid rgb(11, 181, 231)',
                            }}
                          >
                            <RowsExpanded
                              data={row.original.rowGroup}
                              columns={columns}
                              haveRowSelect={haveRowSelect}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  );
                })
              : rows
                  .slice(
                    currentPage * rowsPerPage,
                    currentPage * rowsPerPage + rowsPerPage
                  )
                  .map((row, i) => {
                    prepareRow(row);
                    const rowId =
                      row.original.idEncuesta || `row-${i}-${Date.now()}`; // ID único
                    return (
                      <StyledTableRow
                        className={row.original?.bgColor}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => (
                          <TableCell {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        ))}
                      </StyledTableRow>
                    );
                  })}
            <TableRow>
              <TablePagination
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                count={isDynamic ? totalPages : rows.length}
                labelDisplayedRows={() => ''}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={(props) => (
                  <TablePaginationActions
                    props={props}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setIsLoading={setIsLoading}
                    setExpanded={setExpanded}
                  />
                )}
              />
            </TableRow>
          </TableBody>
        </MaUTable>
      ) : (
        <div className="empty-tab">
          <EmptyCard
            message="Actualmente no existen encuestas registradas"
            img={empty}
          />
        </div>
      )}
    </TableContainer>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loader: PropTypes.bool,
};

export default EnhancedTable;
