import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Paper } from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import TabsUsers from '../../../components/common/TabsUsers';
import Alert from '../../../components/common/Alert';
import InformationUser from './InformationUser';
import Schedule from './Schedule';
import CustomeFields from './CustomFields';
import ListSessions from './ListSessions';
import SetSessions from './SetSessions';
import UsersAPI from '../user-api';
import PillarAPI from '../../PillarContainer/pillar-api';
import { UserContext } from './userContext';
import { useContext } from 'react';
import ColumnSkeleton from '../../../components/common/Skeletons';
import { DatosEmprendimiento } from './InformationUser/Mentees/DatosEmprendimiento';
import { HistorialActividad } from './InformationUser/Mentees/HistorialActividad';
import { DatosAdicionales } from './InformationUser/Mentor/DatosAdicionales';

const UserEditForm = () => {
  const location = useLocation();
  const history = useHistory();
  const { roleType, idUser, clientId, ref } = location.state;
  const [loading, setLoading] = useState(true);
  const [path, setPath] = useState('usuarios');
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    message: '',
    type: '',
  });

  const [state, dispatch] = useContext(UserContext);
  const [reload, setReload] = useState(false);
  const [tabs, setTabs] = useState([
    { title: 'Información usuario' },
    // { title: 'Datos adicionales' },
  ]);

  const [content, setContent] = useState([
    <InformationUser
      setReload={setReload}
      setShowAlert={setShowAlert}
      setMessageAlert={setMessageAlert}
    />,
    // <CustomeFields />,
  ]);

  // const [contentTabs, setContentTabs] = useState([]);

  // useEffect(() => {
  //   if (roleType === 'mentee') {
  //     setContent((prev) => [
  //       ...prev,
  //       <DatosEmprendimiento
  //         setShowAlert={setShowAlert}
  //         setMessageAlert={setMessageAlert}
  //       />,
  //     ]);
  //     setTabs((prev) => [...prev, { title: 'Datos emprendimiento' }]);
  //   }
  // }, [roleType]);

  let timer;

  // useEffect(() => {
  //   //info about user
  //   const { roleType, idUser, clientId, ref } = location.state;

  //   let promises = [UsersAPI.getUser(idUser), PillarAPI.fetchActivePillars()];

  //   if (roleType === 'mentor') {
  //     setContent((prev) => [...prev, <Schedule />]);
  //     promises = [...promises, UsersAPI.getMentorCalendar(idUser)];
  //     setTabs((prev) => [...prev, { title: 'Horarios' }]);
  //   }
  //   if (roleType === 'mentee') {
  //     promises = [...promises, UsersAPI.getMentors(clientId, ref)];
  //     setTabs((prev) => [...prev, { title: 'Reuniones futuras' }]);
  //     setContent((prev) => [...prev, <SetSessions />]);
  //   }
  //   if (roleType !== 'admin' && roleType !== 'manager') {
  //     promises = [...promises, UsersAPI.getSessions(idUser)];
  //     setContent((prev) => [...prev, <ListSessions />]);
  //     setTabs((prev) => [...prev, { title: 'Lista de reuniones' }]);
  //   }

  //   //prevents that all promises will fail if one have.
  //   Promise.allSettled(promises)
  //     .then((response) => {
  //       const [userResponse, pillarsResponse, thirdResponse, sessionsResponse] =
  //         response;

  //       const { extra, extraData, ...rest } = userResponse.value.data;
  //       //used for completing form
  //       let userInfo = {
  //         ...extra,
  //         ...extraData,
  //         ...rest,
  //         sessions: sessionsResponse.value.data,
  //       };

  //       if (roleType === 'mentee') {
  //         dispatch(
  //           {
  //             type: 'FETCH_INITIAL_STATE',
  //             payload: {
  //               user: userInfo,
  //               pillars: pillarsResponse.value.data,
  //               mentors: thirdResponse.value.data,
  //             },
  //           },
  //           null
  //         );
  //       }
  //       if (roleType === 'mentor') {
  //         dispatch(
  //           {
  //             type: 'FETCH_INITIAL_STATE',
  //             payload: {
  //               user: userInfo,
  //               pillars: pillarsResponse.value.data,
  //               schedule: thirdResponse.value?.data || [],
  //             },
  //           },
  //           null
  //         );
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    //info about user

    let promises = [
      UsersAPI.getUserById(idUser),
      PillarAPI.fetchActivePillars(),
    ];

    if (roleType === 'mentor') {
      promises = [...promises, UsersAPI.getMentors(clientId, ref)];
      setContent((prev) => [...prev]);
      setTabs((prev) => [...prev, { title: 'Datos adicionales' }]);

      setContent((prev) => [
        ...prev,
        <DatosAdicionales
          setShowAlert={setShowAlert}
          setMessageAlert={setMessageAlert}
        />,
      ]);
    }
    if (roleType === 'mentee') {
      promises = [...promises, UsersAPI.getMentors(clientId, ref)];
      setContent((prev) => [...prev]);
      setTabs((prev) => {
        const newTabs = [
          { title: 'Datos emprendimiento' },
          { title: 'Reuniones futuras' },
        ];

        return [
          ...prev,
          ...newTabs.filter(
            (newTab) => !prev.some((tab) => tab.title === newTab.title)
          ),
        ];
      });

      setContent((prev) => [
        ...prev,
        <DatosEmprendimiento
          setShowAlert={setShowAlert}
          setMessageAlert={setMessageAlert}
        />,
        <SetSessions />,
      ]);
    }
    // if (roleType !== 'admin' && roleType !== 'manager') {
    //   promises = [...promises, UsersAPI.getSessions(idUser)];
    //   setContent((prev) => [...prev, <ListSessions />]);
    //   setTabs((prev) => [...prev, { title: 'Lista de reuniones' }]);
    // }
    if (roleType !== 'admin' && roleType !== 'manager') {
      promises = [...promises, UsersAPI.getSessions(idUser)];
      setContent((prev) => [...prev, <HistorialActividad />]);
      setTabs((prev) => {
        const newTab = { title: 'Historial de actividad' };

        return prev.some((tab) => tab.title === newTab.title)
          ? prev
          : [...prev, newTab];
      });
    }

    //prevents that all promises will fail if one have.
    Promise.allSettled(promises)
      .then((response) => {
        const [userResponse, pillarsResponse, thirdResponse, sessionsResponse] =
          response;

        const { extra, extraData, ...rest } = userResponse.value.data;
        //used for completing form
        let userInfo = {
          ...extra,
          ...extraData,
          ...rest,
          sessions: sessionsResponse.value.data,
        };

        if (roleType === 'mentee') {
          dispatch(
            {
              type: 'FETCH_INITIAL_STATE',
              payload: {
                user: userInfo,
                pillars: pillarsResponse.value.data,
                mentors: thirdResponse.value.data,
              },
            },
            null
          );
        }
        if (roleType === 'mentor') {
          dispatch(
            {
              type: 'FETCH_INITIAL_STATE',
              payload: {
                user: userInfo,
                pillars: pillarsResponse.value.data,
                schedule: thirdResponse.value?.data || [],
              },
            },
            null
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reload]);

  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setShowAlert(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div style={{ minHeight: 'calc(100vh - 88px)' }}>
      {loading ? (
        <Grid container className="user-container" spacing={4}>
          <ColumnSkeleton rows={8} />
          <ColumnSkeleton rows={8} />
          <ColumnSkeleton rows={8} />
        </Grid>
      ) : (
        <Grid container className="user-container">
          <Grid item xs={12}>
            {showAlert && (
              <Alert type={messageAlert.type}>{messageAlert.message}</Alert>
            )}
            <Grid container className="user-container__edit" direction="column">
              {/* <Grid item className="title" style={{ backgroundColor: 'white' }}>
                <Edit />
                <span>
                  Editar información del &nbsp;
                  {path === 'usuarios' ? 'usuario' : 'mentor'}
                </span>
              </Grid> */}
              <Grid item className="tabs-edit-user">
                <TabsUsers tabs={tabs} content={content} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default UserEditForm;
