// create a react component that will display the summary of the meeting
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Divider,
} from '@material-ui/core';
import SessionAPI from '../session-api';
import RefreshIcon from '@material-ui/icons/Refresh';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export default function MeetReport({ sessionId }) {
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function FormatDate({ time }) {
    const date = format(new Date(time), 'dd/MM/yyyy HH:mm:ss', { locale: es, timeZone: 'America/Santiago' });
    return (
      <span>
        {date}
      </span>
    );
  }

  function updateParticipants() {
    setIsLoading(true);
    SessionAPI.getParticipantsBySession(sessionId)
      .then((response) => {
        setParticipants(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    SessionAPI.getParticipantsBySession(sessionId)
      .then((response) => {
        setParticipants(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [sessionId]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Reporte de la reunión
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer !important' }}
              onClick={() => updateParticipants()}
            >
              <RefreshIcon
                style={{
                  cursor: 'pointer !important',
                  marginRight: '8px',
                  animation: isLoading ? 'spin 2s linear infinite' : 'none',
                }}
              />
              <Typography variant="body2">Actualizar</Typography>
            </Box>
          </Box>
          <Divider />
          <Typography style={{ padding: '16px' }}>
            Esta sección solo muestra contenido para reuniones realizadas en
            Google Meet.
          </Typography>

          {participants.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Participante</TableCell>
                    <TableCell>Hora de entrada</TableCell>
                    <TableCell>Hora de salida</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participants.map((participant, index) => (
                    <TableRow key={index}>
                      <TableCell>{participant.name}</TableCell>
                      <TableCell>
                        <FormatDate time={participant.startTime} />
                      </TableCell>
                      <TableCell>
                        <FormatDate time={participant.endTime} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography style={{ padding: '16px' }}>
              No hay participantes para mostrar.
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
