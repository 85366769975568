import { Button, Grid, IconButton, Table } from '@material-ui/core';
import {
  FileCopyOutlined,
  PeopleAltOutlined,
  SchoolOutlined,
  ShopOutlined,
  SlideshowOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TableGeneralNew from '../../../../../../components/common/TableGeneralNew';
import {
  getCoursesData,
  getMentorshipsData,
  getWorkshopsData,
} from './historialData';
import { UserContext } from '../../../userContext';
import { formatDate } from '../../../../../../utils/format';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Spinner from '../../../../../../components/common/Spinner';

export const HistorialActividad = () => {
  const [state] = useContext(UserContext);
  const history = useHistory();
  const { user } = state;
  const [activeCategory, setActiveCategory] = useState('Mentorías');
  const [countRegisters, setCountRegisters] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (activeCategory === 'Mentorías') {
        await getMentorshipsData(
          user.id,
          currentPage,
          setData,
          setIsLoading,
          setTotalPages,
          setCountRegisters
        );
      }
      if (activeCategory === 'Talleres') {
        await getWorkshopsData(
          user.id,
          currentPage,
          setData,
          setIsLoading,
          setTotalPages,
          setCountRegisters
        );
      }
      if (activeCategory === 'Cursos') {
        await getCoursesData(
          user.id,
          currentPage,
          setData,
          setIsLoading,
          setTotalPages,
          setCountRegisters
        );
      }
    } catch (error) {
      console.error('Error al cargar datos:', error);
      setData([]);
      setIsLoading(false);
    }
  }, [activeCategory, currentPage, user]);

  useEffect(() => {
    if (user?.id) {
      getData();
    }
  }, [getData, user]);

  const columns = useMemo(() => {
    switch (activeCategory) {
      case 'Mentorías':
        return [
          {
            Header: 'Asunto',
            accessor: 'id',
            Cell: ({ value }) => <span>{value}</span>,
          },
          {
            Header: 'Nombre mentor',
            accessor: 'mentorName',
            Cell: ({ value }) => <span>{value}</span>,
          },
          {
            Header: 'Fecha de sesión',
            accessor: 'createdAt',
            Cell: ({ value }) => (
              <span>
                {formatDate(parseInt(value, 10), 'DD/MM/YYYY HH:mm')} hrs.
              </span>
            ),
          },
          {
            Header: 'Pilar',
            accessor: 'pillarName',
            Cell: ({ value }) => <span>{value}</span>,
          },
          {
            Header: 'Estado',
            accessor: 'status',
            Cell: ({ value }) => <span>{value || 'N/A'}</span>,
          },
          {
            id: 'actions',
            Cell: ({ row: { original } }) => (
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <IconButton>
                    <VisibilityOutlined />
                  </IconButton>
                </Grid>
                <Grid item xs={3}>
                  <IconButton>
                    <FileCopyOutlined />
                  </IconButton>
                </Grid>
              </Grid>
            ),
          },
        ];
      case 'Talleres':
        return [
          {
            Header: 'Nombre taller',
            accessor: 'workshopName',
            width: '75%',
            isCentered: false,
            Cell: ({ value }) => <span>{value ? value : '-'}</span>,
          },
          {
            Header: 'Fecha de visualización',
            accessor: 'createdAt',
            width: '25%',
            isCentered: false,
            Cell: ({ value }) => (
              <span>
                {formatDate(parseInt(value, 10), 'DD/MM/YYYY HH:mm')} hrs.
              </span>
            ),
          },
        ];
      case 'Cursos':
        return [
          {
            Header: 'Nombre de curso',
            accessor: 'name',
            width: '50%',
            isCentered: false,
          },
          {
            Header: 'Pilar',
            accessor: 'pillar.name',
            width: '25%',
            isCentered: false,
          },
          {
            Header: 'Estado del curso',
            accessor: 'active',
            Cell: ({ value }) => <span>{value ? 'Activo' : 'Inactivo'}</span>,
            width: '25%',
            isCentered: false,
          },
        ];
      default:
        return [];
    }
  }, [history, activeCategory]);

  const onChangeTable = (category) => {
    // Only proceed if the category is different from the current active one
    if (category !== activeCategory) {
      setData([]);
      setCurrentPage(1);
      setTotalPages(0);
      setCountRegisters(0);
      setActiveCategory(category);
      setIsLoading(true);
    }
  };

  return (
    <div className="container-historial">
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={4}>
          <h2 className="title-history">Historial de actividad</h2>
        </Grid>
        <Grid container item xs={5} style={{ columnGap: '12px' }}>
          <Grid item>
            <Button
              key={'Mentorías'}
              className={`category-button ${
                activeCategory === 'Mentorías' ? 'active' : ''
              }`}
              onClick={() => onChangeTable('Mentorías')}
              startIcon={<PeopleAltOutlined />}
              style={{ width: '157px' }}
            >
              Mentorías
            </Button>
          </Grid>
          <Grid item>
            <Button
              key={'Talleres'}
              className={`category-button ${
                activeCategory === 'Talleres' ? 'active' : ''
              }`}
              onClick={() => onChangeTable('Talleres')}
              startIcon={<SlideshowOutlined />}
              style={{ width: '132px' }}
            >
              Talleres
            </Button>
          </Grid>
          <Grid item>
            <Button
              key={'Cursos'}
              className={`category-button ${
                activeCategory === 'Cursos' ? 'active' : ''
              }`}
              onClick={() => onChangeTable('Cursos')}
              startIcon={<SchoolOutlined />}
              style={{ width: '121px' }}
            >
              Cursos
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ rowGap: '12px', marginTop: '32px' }}
      >
        <Grid item xs={12}>
          <span className="title-table">
            {activeCategory} ({countRegisters})
          </span>
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Spinner style={{ paddingTop: '32px' }} />
          ) : (
            <TableGeneralNew
              data={data}
              columns={columns}
              isDinamic
              isLoadingData={isLoading}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};