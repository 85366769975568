import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import ControlPointSharpIcon from '@material-ui/icons/ControlPointSharp';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React from 'react';

export const FormSession = ({
  alertMessage,
  mentorList,
  message,
  setMentor,
  description,
  setDescription,
  setSubject,
  subject,
  guestEmails,
  addGuest,
  removeGuestEmail,
  inputInvitado,
  setInputInvitado,
}) => {
  return (
    <Grid
      container
      item
      xs={4}
      justifyContent="center"
      style={{
        borderRight: '1px solid #0000001A ',
        height: 'inherit',
      }}
    >
      <Grid
        container
        item
        xs={10}
        alignContent="flex-start"
        style={{ rowGap: '24px', margin: '17px 0' }}
      >
        <Grid item xs={12}>
          {alertMessage.message && (
            <Alert type={alertMessage.type}>{alertMessage.message}</Alert>
          )}
          <h3 style={{ fontSize: '18px', paddingTop: '30px' }}>
            Agendar reuniones futuras
          </h3>
        </Grid>
        <Grid item xs={12}>
          <span style={{ fontSize: '16px' }}>
            <b>Detalle de la reunión</b>
          </span>
          <p style={{ fontSize: '14px', color: '#25282B', opacity: '50%' }}>
            Ingresa los participantes y el detalle de la reunión
          </p>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth className="textField-form">
            <InputLabel id="custom-select-label">Mentor</InputLabel>
            <Select
              labelId="custom-select-label"
              name="mentorId"
              onChange={(event) => {
                setMentor(event.target.value);
              }}
              label="Nombre Mentor"
              MenuProps={{
                classes: { paper: 'selectMenu' },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                PaperProps: {
                  style: {
                    marginTop: 8,
                  },
                },
              }}
              IconComponent={ExpandMoreOutlined}
            >
              {mentorList.map((option) => {
                if (option.id !== undefined) {
                  return (
                    <MenuItem key={option.value} value={option.id}>
                      {option.value}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            {message.mentor && (
              <div className="error-message">{message.mentor}</div>
            )}
          </FormControl>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <TextField
              name="guestEmail"
              label="Invitado (usuario@mail.com)"
              placeholder=""
              value={inputInvitado}
              onChange={(event) => {
                setInputInvitado(event.target.value);
              }}
              variant="outlined"
              fullWidth
              className="textField-form"
              InputProps={{
                style: {
                  padding: '12px 8px', // Ajuste del padding interno
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                margin: '10px 0',
                color: '#0BB5E7',
                display: 'flex',
                cursor: 'pointer',
              }}
              onClick={() => addGuest(inputInvitado, guestEmails)}
            >
              <ControlPointSharpIcon />
              Agregar invitado
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="list-invitado">
              {guestEmails.map((email, index) => (
                <div className="item-list" key={index}>
                  {email}
                  <span
                    className="material-icons icon"
                    onClick={() => removeGuestEmail(index)}
                  >
                    close
                  </span>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="subject"
            label="Asunto"
            value={subject}
            onChange={(event) => {
              setSubject(event.target.value);
            }}
            placeholder=""
            variant="outlined"
            fullWidth
            className="textField-form"
            InputProps={{
              style: {
                padding: '12px 8px', // Ajuste del padding interno
              },
            }}
          />
          {message.subject && (
            <div className="error-message">{message.subject}</div>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Descripción"
            multiline
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            placeholder=""
            variant="outlined"
            fullWidth
            className="textFieldArea-form"
            InputProps={{
              style: {
                padding: '12px 8px', // Ajuste del padding interno
              },
            }}
          />
          <div style={{ textAlign: 'right' }}>
            <Typography variant="caption" color="textSecondary" align="right">
              {description.length}/{1000}
            </Typography>
          </div>

          {message.description && (
            <div className="error-message">{message.description}</div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
